<template>
  <div class="text-center" v-if="loading">
    <v-progress-circular
      :size="80"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <v-row v-else>
    <v-col md="12">

      <h1 class="title_list">{{ $t('myRides') }}</h1>

      <v-card max-width="687" tile class="carMenu">
        <v-list dense>
          <v-col cols="12">
            <v-text-field
              v-model="searchQuery"
              :label="$t('searchRides')"
              append-icon="mdi-magnify"
              clearable
              :loading="loadingSearch"
              @click:append="search"
              @click:clear="clear"
              @keyup.enter="search"
            ></v-text-field>
          </v-col>

          <v-list-item-group v-model="selectedSpin">
            <v-list-item
              v-for="spin in spins"
              :key="spin._id"
              @click="$router.push({ path: `/spins/${spin._id}` })"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="spin.brand + ' ' + spin.model"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <!-- <v-icon v-if="allImages(spin)" color="green">
                  mdi-check
                </v-icon>
                <v-icon v-else color="red"> mdi-close </v-icon> -->
                <v-btn
                  @click.prevent="$router.push({ path: `/spins/${spin._id}` })"
                  text
                  icon
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <!-- <v-col v-if="spins.length && selectedSpin" md="6">
      <preview :spin="spins[selectedSpin]" />
    </v-col> -->
  </v-row>
</template>
<script>
import { getMySpins, searchSpins } from "@/api";
// import Preview from "@/components/player/Preview.vue";

export default {
  components: {
    // Preview,
  },
  data: () => ({
    spins: [],
    selectedSpin: null,
    loading: false,
    searchQuery: "",
    loadingSearch: false,
  }),
  created() {
    this.fetchSpins();
  },
  mounted() {},
  methods: {
    async fetchSpins() {
      this.loading = true;
      this.spins = await getMySpins();
      this.loading = false;
    },
    async search() {
      if (!this.searchQuery.trim()) return;
      this.loadingSearch = true;
      this.spins = await searchSpins(this.searchQuery);
      this.loadingSearch = false;
    },
    async clear() {
      this.searchQuery = "";
      this.loadingSearch = true;
      this.spins = await getMySpins();
      this.loadingSearch = false;
    },
    allImages(spin) {
      if (
        spin.exteriorImages.length &&
        spin.interiorImages.length &&
        spin.images360.length /* &&
        spin.panorama.length */
      )
        return true;
      return false;
    },
  },
};
</script>

<style>
.carMenu {
  margin: 0 auto 40px auto;
}

.carMenu .v-list-item:hover i {
  color: #1a76d2;
}

.carMenu .v-list-item__title {
  font-size: 1.125em !important;
  text-transform: capitalize;
  font-weight: 400 !important;
  line-height: 1.5em !important;
}
.carMenu .v-list-item__title:hover {
}
.carMenu .v-btn--round {
  margin-top: -5px;
}
.carMenu .v-list--dense .v-input {
  height: 40px;
  padding-top: 0;
}

.title_list {color: #1a76d2 !important; max-width:687px;margin: 27px auto 10px auto; padding: 0 0 0 15px;}

.carMenu .v-label {font-size:1.125em !important;}
.carMenu .col-12 { padding: 6px 17px 4px 17px;}
</style>
