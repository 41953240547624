<template>
  <List />
</template>
<script>
import List from "@/components/spins/List";

export default {
  components: {
    List,
  },
};
</script>